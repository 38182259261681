.checkInWrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
}
.checkInBanner {
    display: flex;
    height: 150px;
    width: 100vw;
    background-image: url(../../resources/carelon/crl_teams_backgrounds_progress_rgb_pale_cyan.png);
    background-repeat: no-repeat;
    background-size: 2000px 200px;
    background-position: bottom;
    justify-content: center;
    align-items: center;
}
.checkInBanner>span {
    font-size: 5rem;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #5009B5;
    text-transform: uppercase;
}
.checkInHomeContainer {
    height: calc(100vh - 130px);
    /* height: 100%; */
    width: 100%;
    display: flex;
    flex-direction: row;
}
.checkInSidePanel {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f0f1f2;
    justify-content: center;
}

.checkInMainPanel {
    flex: 2;
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    place-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .checkInHomeContainer {
        /* height: calc(100vh - 130px); */
        height: 100%;
        width: 100%;
        flex-direction: column;
    }
    .checkInBanner {
        height: 70px;
        width: 100vw;
        background-size: 900px 100px;
    }
    .checkInBanner>span {
        font-size: 1.7rem;
    }
    .checkInMainPanel {
        flex: 3;
    }
}